import React, { useState, useRef, useEffect } from 'react';
import { SendHorizontal, Pause, Play, X, ArrowLeft } from 'lucide-react';
import RecentSearches from './RecentSearches';

const placeholders = [
  "Best selling SKUs",
  "Total sales to USA",
  "Last 5 transactions"
];

const TYPING_INTERVAL = 45;
const ERASING_INTERVAL = 20;
const PAUSE_DURATION = 9000;

const QueryInput = ({ 
  onSubmit, 
  isLoading, 
  isFollowUp = false, 
  isFollowUpMode = false, 
  selectedText = '', 
  selectedColumns = [], 
  isCardTitle = false, 
  fetchRecentSearches,
  initialQuery = ''
}) => {
  const [inputQuery, setInputQuery] = useState(initialQuery);
  const [placeholder, setPlaceholder] = useState('');
  const [isPaused, setIsPaused] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [showRecentSearches, setShowRecentSearches] = useState(false);
  const [recentSearches, setRecentSearches] = useState([]);
  const [filteredRecentSearches, setFilteredRecentSearches] = useState([]);
  const [maxDisplayedSearches, setMaxDisplayedSearches] = useState(4);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const [historyIndex, setHistoryIndex] = useState(-1);
  const [selectedHistoricalQuery, setSelectedHistoricalQuery] = useState(null);
  const textareaRef = useRef(null);
  const chipRef = useRef(null);
  const columnsChipRef = useRef(null);
  const timeoutRef = useRef(null);
  const recentSearchesRef = useRef(null);

useEffect(() => {
  if (isFollowUpMode && textareaRef.current) {
    // Small timeout to ensure the modal is fully rendered
    setTimeout(() => {
      textareaRef.current.focus();
    }, 100);
  }
}, [isFollowUpMode]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputQuery.trim()) {
      if (selectedHistoricalQuery) {
        // If query comes from history navigation, use the stored query
        onSubmit(selectedHistoricalQuery);
        setSelectedHistoricalQuery(null); // Clear after use
      } else {
        // Otherwise, always process as new query
        if (isFollowUpMode) {
          await onSubmit(inputQuery, { text: selectedText, columns: selectedColumns });
        } else {
          await onSubmit(inputQuery, isFollowUpMode, selectedText);
        }
      }
      setShowRecentSearches(false);
      refreshRecentSearches();
    }
  };

  const refreshRecentSearches = async () => {
    const searches = await fetchRecentSearches();
    setRecentSearches(searches);
    filterRecentSearches(inputQuery, searches);
    localStorage.setItem('recentSearches', JSON.stringify(searches));
  };

  const SelectedColumnsChip = ({ columns }) => (
    <div className="flex flex-wrap gap-1 mb-2">
      {columns.map(column => (
        <span key={column} className="bg-secondary text-white text-xs px-2 py-1 rounded-full">
          {column}
        </span>
      ))}
    </div>
  );

  const loadRecentSearchesFromLocalStorage = () => {
    const storedSearches = localStorage.getItem('recentSearches');
    if (storedSearches) {
      const parsedSearches = JSON.parse(storedSearches);
      setRecentSearches(parsedSearches);
      filterRecentSearches(inputQuery, parsedSearches);
    }
  };

  const filterRecentSearches = (query, searches = recentSearches) => {
    if (!Array.isArray(searches)) {
      console.warn('Received invalid searches:', searches);
      return setFilteredRecentSearches([]);
    }
  
    const filtered = searches.filter(search => {
      if (!search || typeof search.userQuery !== 'string') {
        console.warn('Invalid search entry:', search);
        return false;
      }
      try {
        return search.userQuery.toLowerCase().includes((query || '').toLowerCase());
      } catch (error) {
        console.warn('Error filtering search:', error);
        return false;
      }
    });
    setFilteredRecentSearches(filtered.slice(0, maxDisplayedSearches));
  };

  const handleHistoryBack = async () => {
    if (recentSearches.length === 0) {
      const searches = await fetchRecentSearches();
      if (searches && searches.length > 0) {
        setRecentSearches(searches);
        if (historyIndex === -1) {
          setHistoryIndex(0);
          setInputQuery(searches[0].userQuery);
          setSelectedHistoricalQuery(searches[0]);
        } else if (historyIndex < searches.length - 1) {
          const newIndex = historyIndex + 1;
          setHistoryIndex(newIndex);
          setInputQuery(searches[newIndex].userQuery);
          setSelectedHistoricalQuery(searches[newIndex]);
        }
      }
    } else {
      if (historyIndex === -1) {
        setHistoryIndex(0);
        setInputQuery(recentSearches[0].userQuery);
        setSelectedHistoricalQuery(recentSearches[0]);
      } else if (historyIndex < recentSearches.length - 1) {
        const newIndex = historyIndex + 1;
        setHistoryIndex(newIndex);
        setInputQuery(recentSearches[newIndex].userQuery);
        setSelectedHistoricalQuery(recentSearches[newIndex]);
      }
    }
    adjustTextareaHeight();
  };

  // Add effect to reset history index when query is manually changed
  useEffect(() => {
    if (inputQuery !== (recentSearches[historyIndex]?.userQuery || '')) {
      setHistoryIndex(-1);
    }
  }, [inputQuery]);

  const handleTextareaChange = (e) => {
    const newQuery = e.target.value;
    setInputQuery(newQuery);
    // Any manual text change clears the historical query
    setSelectedHistoricalQuery(null);
    filterRecentSearches(newQuery);
    adjustTextareaHeight();
    setHighlightedIndex(-1);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (highlightedIndex !== -1 && filteredRecentSearches.length > 0) {
        handleSelectRecentQuery(filteredRecentSearches[highlightedIndex]);
      } else {
        handleSubmit(e);
      }
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredRecentSearches.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setHighlightedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : -1));
    } else if (e.key === 'Escape') {
      setShowRecentSearches(false);
      setHighlightedIndex(-1);
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      const newHeight = Math.min(textarea.scrollHeight, 3 * 24);
      textarea.style.height = `${newHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [inputQuery]);

  useEffect(() => {
    if (isFollowUpMode) {
      if (textareaRef.current && chipRef.current && columnsChipRef.current) {
        const chipWidth = chipRef.current.offsetWidth;
        const columnsChipWidth = columnsChipRef.current.offsetWidth;
        const paddingLeft = 10;
        const cursorPosition = paddingLeft + chipWidth + columnsChipWidth + 10;
        textareaRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
      return;
    }

    let currentPlaceholderIndex = 0;
    let currentCharIndex = 0;
    let isErasing = false;

    const animatePlaceholder = () => {
      if (isPaused) {
        timeoutRef.current = setTimeout(animatePlaceholder, 100);
        return;
      }

      const currentPlaceholder = placeholders[currentPlaceholderIndex];

      if (!isErasing && currentCharIndex <= currentPlaceholder.length) {
        setPlaceholder(currentPlaceholder.slice(0, currentCharIndex));
        currentCharIndex++;
        timeoutRef.current = setTimeout(animatePlaceholder, TYPING_INTERVAL);
      } else if (!isErasing && currentCharIndex > currentPlaceholder.length) {
        isErasing = true;
        timeoutRef.current = setTimeout(animatePlaceholder, PAUSE_DURATION);
      } else if (isErasing && currentCharIndex > 0) {
        currentCharIndex--;
        setPlaceholder(currentPlaceholder.slice(0, currentCharIndex));
        timeoutRef.current = setTimeout(animatePlaceholder, ERASING_INTERVAL);
      } else if (isErasing && currentCharIndex === 0) {
        isErasing = false;
        currentPlaceholderIndex = (currentPlaceholderIndex + 1) % placeholders.length;
        timeoutRef.current = setTimeout(animatePlaceholder, TYPING_INTERVAL);
      }
    };

    animatePlaceholder();

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isPaused, isFollowUpMode]);

  /*
  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }

    const handleScroll = () => {
      if (textareaRef.current && document.activeElement === textareaRef.current) {
        textareaRef.current.blur();
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  */

  useEffect(() => {
    // Load from localStorage first for immediate availability
    loadRecentSearchesFromLocalStorage();
    
    // Then fetch fresh data
    const loadInitialSearches = async () => {
      const searches = await fetchRecentSearches();
      if (searches) {
        setRecentSearches(searches);
        filterRecentSearches(inputQuery, searches);
      }
    };
    
    loadInitialSearches();
  }, []); // Run once on mount
  
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (recentSearchesRef.current && !recentSearchesRef.current.contains(event.target) &&
          textareaRef.current && !textareaRef.current.contains(event.target)) {
        setShowRecentSearches(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (initialQuery) {
      setInputQuery(initialQuery);
      adjustTextareaHeight();
    }
  }, [initialQuery]);

  const togglePause = () => {
    setIsPaused(!isPaused);
  };

  const clearInput = () => {
    setInputQuery('');
    if (textareaRef.current) {
      // Temporarily blur the textarea
      textareaRef.current.blur();
      // Re-focus to trigger handleFocus
      setTimeout(() => {
        textareaRef.current.focus();
      }, 0);
    }
  };

  const truncateSelectedText = (text) => {
    if (typeof text === 'boolean') {
      return 'Follow-up';
    }
    return text && text.length > 20 ? text.substring(0, 17) + '...' : text;
  };

  const handleSelectRecentQuery = (recentQuery) => {
    setInputQuery(recentQuery.userQuery);
    setShowRecentSearches(false);
    setHighlightedIndex(-1);
    onSubmit(recentQuery);
  };

  const handleFocus = async () => {
    setIsFocused(true);
    if (!isFollowUpMode) {
      // First load from localStorage for immediate display
      loadRecentSearchesFromLocalStorage();
      setShowRecentSearches(true);
      
      // Then fetch fresh data
      const searches = await fetchRecentSearches();
      if (searches) {
        setRecentSearches(searches);
        filterRecentSearches(inputQuery, searches);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit} className="mb-4 relative">
      <div className="relative">
        {isFollowUpMode && selectedColumns.length > 0 && (
          <SelectedColumnsChip columns={selectedColumns} />
        )}
        <div className="relative">
          {isFollowUpMode && (
            <>
              <div 
                ref={chipRef}
                className={`selected-text-chip absolute left-2.5 top-3 text-xs px-2 py-1 rounded-full ${
                  isCardTitle ? 'bg-white text-gray-700' : 'bg-secondary text-text'
                }`}
              >
                {selectedText ? truncateSelectedText(selectedText) : truncateSelectedText(isCardTitle)}
              </div>
              {selectedColumns.length > 0 && (
                <div 
                  ref={columnsChipRef}
                  className="columns-chip absolute left-2.5 top-9 text-xs px-2 py-1 rounded-full bg-primary text-white"
                >
                  {selectedColumns.join(', ')}
                </div>
              )}
            </>
          )}
          <textarea
            ref={textareaRef}
            value={inputQuery}
            onChange={handleTextareaChange}
            onKeyDown={handleKeyDown}
            onFocus={handleFocus}
            onBlur={() => {
              setIsFocused(false);
              setTimeout(() => setShowRecentSearches(false), 200);
            }}
            placeholder={placeholder}
            className={`w-full py-3 bg-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-secondary text-white text-base placeholder-gray-400 min-h-[48px] max-h-[72px] resize-none overflow-y-auto ${
              isFollowUpMode ? 'pl-4' : 'pl-10'
            } pr-48 ${
              isFocused ? 'ring-2 ring-secondary' : ''
            }`}
            disabled={isLoading}
            rows={1}
            style={{
              lineHeight: '24px',
              transition: 'height 0.1s ease-out',
              paddingLeft: isFollowUpMode 
                ? `${10 + Math.max(chipRef.current?.offsetWidth || 0, columnsChipRef.current?.offsetWidth || 0) + 5}px` 
                : undefined,
              paddingTop: isFollowUpMode && selectedColumns.length > 0 ? '40px' : undefined,
            }}
          />
        </div>
        {!isFollowUpMode && (
          <img
            src="/favicon.ico"
            alt="Company Icon"
            className="absolute left-2.5 top-3 w-6 h-6"
          />
        )}
        <div className="absolute right-2 top-3 flex items-center space-x-2">
          {!isFollowUpMode && (
            <button
              type="button"
              onClick={handleHistoryBack}
              disabled={isLoading || (historyIndex === recentSearches.length - 1)}
              className={`p-1 rounded-full transition-colors duration-200 ${
                (!isLoading && (historyIndex < recentSearches.length - 1 || historyIndex === -1)) 
                  ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' 
                  : 'text-text-lighter'
              }`}
              title="Show previous question"
            >
              <ArrowLeft size={20} />
            </button>
          )}
          <button
            type="button"
            onClick={inputQuery ? clearInput : togglePause}
            className={`p-1 rounded-full transition-colors duration-200 ${
              inputQuery ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' : 'text-text-lighter'
            }`}
          >
            {inputQuery ? <X size={20} /> : isPaused ? <Play size={20} /> : <Pause size={20} />}
          </button>
          <button
            type="submit"
            className={`p-1 rounded-full transition-colors duration-200 ${
              inputQuery.trim() ? 'text-secondary hover:bg-secondary hover:bg-opacity-10' : 'text-text-lighter'
            }`}
            disabled={isLoading || !inputQuery.trim()}
          >
            <SendHorizontal size={20} />
          </button>
        </div>
      </div>
      {!isFollowUpMode && (
        <div ref={recentSearchesRef}>
          <RecentSearches
            onSelectQuery={handleSelectRecentQuery}
            isVisible={showRecentSearches}
            setIsVisible={setShowRecentSearches}
            recentSearches={filteredRecentSearches}
            highlightedIndex={highlightedIndex}
            onKeyDown={handleKeyDown}
          />
        </div>
      )}
      {isLoading && (
        <div className="h-10 mt-4">
          <div className="flex justify-center items-center">
            <div className="bubbling-loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
            <span className="ml-2 text-[#4B5563] text-base">Processing query...</span>
          </div>
        </div>
      )}
    </form>
  );
};

export default QueryInput;