import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const UserProfileModal = ({ isOpen, onClose, userEmail }) => {
  const { saveUserPreferences } = useAuth();
  const [formData, setFormData] = useState({
    hebrewName: '',
    englishName: '',
    role: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', userEmail);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setFormData({
            hebrewName: data.hebrewName || '',
            englishName: data.englishName || '',
            role: data.role || ''
          });
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      }
    };

    if (isOpen && userEmail) {
      fetchUserPreferences();
    }
  }, [isOpen, userEmail]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.hebrewName.trim()) {
      newErrors.hebrewName = 'Hebrew name is required';
    }
    if (!formData.englishName.trim()) {
      newErrors.englishName = 'English name is required';
    }
    
    const sanitizedRole = formData.role.trim();
    if (!sanitizedRole) {
      newErrors.role = 'Role is required';
    } else if (sanitizedRole.length < 3) {
      newErrors.role = 'Role must be at least 3 characters long';
    } else if (!/^[\p{L}\s"״״]+$/u.test(sanitizedRole)) {
      newErrors.role = 'Role can only contain letters, spaces, and quotation marks';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    
    if (validateForm()) {
      setIsLoading(true);
      try {
        await saveUserPreferences(userEmail, {
          hebrewName: formData.hebrewName.trim(),
          englishName: formData.englishName.trim(),
          role: formData.role.trim()
        });
        
        setSuccessMessage('Profile updated successfully!');
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 2000);
      } catch (error) {
        console.error('Error saving preferences:', error);
        setErrors({ submit: 'Failed to update profile. Please try again.' });
      } finally {
        setIsLoading(false);
      }
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md relative">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-gray-400 hover:text-gray-600"
        >
          <X className="h-5 w-5" />
        </button>

        <div className="bg-gray-100 px-6 py-4 rounded-t-lg">
          <h2 className="text-xl font-semibold text-gray-800">Profile Settings</h2>
          <p className="text-sm text-gray-600 mt-1">
            Update your personal information
          </p>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name (Hebrew)
              </label>
              <input
                type="text"
                value={formData.hebrewName}
                onChange={(e) => setFormData(prev => ({ ...prev, hebrewName: e.target.value }))}
                className={`w-full p-2 border rounded-md ${errors.hebrewName ? 'border-red-500' : 'border-gray-300'}`}
                dir="rtl"
              />
              {errors.hebrewName && (
                <p className="text-red-500 text-xs mt-1">{errors.hebrewName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Full Name (English)
              </label>
              <input
                type="text"
                value={formData.englishName}
                onChange={(e) => setFormData(prev => ({ ...prev, englishName: e.target.value }))}
                className={`w-full p-2 border rounded-md ${errors.englishName ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.englishName && (
                <p className="text-red-500 text-xs mt-1">{errors.englishName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Role
              </label>
              <input
                type="text"
                value={formData.role}
                onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
                placeholder="Enter your role"
                className={`w-full p-2 border rounded-md ${errors.role ? 'border-red-500' : 'border-gray-300'}`}
              />
              {errors.role && (
                <p className="text-red-500 text-xs mt-1">{errors.role}</p>
              )}
            </div>
          </div>

          {errors.submit && (
            <p className="text-red-500 text-sm mt-4">{errors.submit}</p>
          )}

          {successMessage && (
            <p className="text-green-500 text-sm mt-4">{successMessage}</p>
          )}

          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 disabled:opacity-50"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserProfileModal;