import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import UserPreferencesModal from '../components/UserPreferencesModal';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPreferencesModal, setShowPreferencesModal] = useState(false);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const { signup, saveUserPreferences } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    if (password !== confirmPassword) {
      return setError('Passwords do not match');
    }
    try {
      setError('');
      setMessage('');
      setIsLoading(true);
      console.log('Attempting to sign up with email:', email);
      await signup(email, password);
      setMessage('Account created successfully. Please complete your profile.');
      setShowPreferencesModal(true);
    } catch (error) {
      console.error('Signup error:', error);
      if (error.message.includes('Email not allowed to register')) {
        setError('This email is not allowed to register. Please contact the administrator.');
      } else if (error.message.includes('already exists')) {
        setError(error.message);
      } else {
        setError('Failed to create an account: ' + error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function handlePreferencesSubmit(preferences) {
    try {
      setPreferencesLoading(true);
      await saveUserPreferences(email, preferences);
      setMessage('Registration completed successfully. Redirecting to home page...');
      setTimeout(() => navigate('/'), 1500);
    } catch (error) {
      console.error('Error saving preferences:', error);
      setError('Failed to save preferences: ' + error.message);
    } finally {
      setPreferencesLoading(false);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text-light mb-6 text-center">Sign Up</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">{error}</div>}
          {message && <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-light text-sm font-medium mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-text-light text-sm font-medium mb-2">Password</label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="confirmPassword" className="block text-text-light text-sm font-medium mb-2">Confirm Password</label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Signing Up...' : 'Sign Up'}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center space-y-4 text-sm">
            <div className="w-full border-t border-gray-200"></div>
            <p className="text-text-light">
              Already have an account?{' '}
              <Link to="/login" className="text-primary hover:underline font-medium">
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>

      <UserPreferencesModal
        isOpen={showPreferencesModal}
        onClose={() => setShowPreferencesModal(false)}
        onSubmit={handlePreferencesSubmit}
        isLoading={preferencesLoading}
      />
    </div>
  );
}

export default Signup;