import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

function SignIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { login, checkAdminStatus } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError('');
      console.log('Attempting to sign in with email:', email);
      const userCredential = await login(email, password);
      console.log('User credential after login:', userCredential);
      const adminStatus = await checkAdminStatus(userCredential.user);
      console.log('Admin status after sign in:', adminStatus);
      navigate('/');
    } catch (error) {
      console.error('Sign in error:', error);
      setError('Failed to sign in: ' + error.message);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text mb-6 text-center">Sign In</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && <div className="bg-red-500 text-white px-4 py-3 rounded mb-4" role="alert">{error}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-light text-sm font-medium mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-text-lighter rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-text-light text-sm font-medium mb-2">Password</label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-text-lighter rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300"
            >
              Sign In
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center space-y-4 text-sm">
            <Link to="/reset-password" className="text-primary hover:underline">
              Forgot Password?
            </Link>
            <div className="w-full border-t border-gray-200"></div>
            <p className="text-text-light">
              Don't have an account?{' '}
              <Link to="/signup" className="text-primary hover:underline font-medium">
                Sign up
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;