import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import logo from '../assets/logo.png';
import { 
  LayoutDashboard, 
  FileStack, 
  PieChart, 
  LocateFixed, 
  LogIn, 
  UserPlus, 
  TrendingUp, 
  HelpCircle,
  LogOut 
} from 'lucide-react';
import config from './config';
import BetaBadge from './BetaBadge';
import UserProfileModal from './UserProfileModal';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';

const Sidebar = ({ open, setOpen }) => {
  const { currentUser, logout, isAdmin } = useAuth();
  const location = useLocation();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [userInitials, setUserInitials] = useState('');

  useEffect(() => {
    const fetchUserPreferences = async () => {
      if (currentUser?.email) {
        try {
          const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
          const docSnap = await getDoc(userPrefsRef);
          
          if (docSnap.exists()) {
            const { englishName } = docSnap.data();
            if (englishName) {
              // Generate initials from English name
              const initials = englishName
                .split(' ')
                .map(name => name.charAt(0).toUpperCase())
                .slice(0, 2) // Take first two initials
                .join('');
              setUserInitials(initials);
            }
          }
        } catch (error) {
          console.error('Error fetching user preferences:', error);
        }
      }
    };

    fetchUserPreferences();
  }, [currentUser]);

  console.log('Sidebar - currentUser:', !!currentUser, 'isAdmin:', isAdmin);

  const handleLogoClick = () => {
    window.location.href = '/';
  };

  const pageConfig = {
    Ask: { path: '/', icon: HelpCircle },
    Dashboard: { path: '/dashboard', icon: LayoutDashboard },
    Reports: { path: '/static-dashboard', icon: FileStack },
    Playground: { path: '/dynamic', icon: PieChart },
    Operate: { path: '/optimization', icon: LocateFixed },
    Plan: { path: '/plan', icon: TrendingUp }
  };

  const MenuItem = ({ label }) => {
    const { path, icon: Icon } = pageConfig[label];
    const isActive = location.pathname === path;
    return (
      <Link
        to={path}
        className={`flex items-center px-4 py-2 my-1 rounded-lg text-sm ${
          isActive
            ? 'bg-gray-100 font-medium text-gray-900'
            : 'text-gray-700 hover:bg-gray-50'
        }`}
        onClick={() => {
          if (window.innerWidth < 1024) {
            setOpen(false);
          }
        }}
      >
        <Icon className="mr-3 h-4 w-4" />
        <div className="flex items-center">
          <span>{label}</span>
          {label === 'Plan' && <BetaBadge />}
        </div>
      </Link>
    );
  };

  const renderMenuItems = () => {
    const pages = isAdmin ? config.sidebarPages.admin : config.sidebarPages.user;
    return pages.map((label, index) => (
      <MenuItem key={index} label={label} />
    ));
  };

  return (
    <>
      {/* Backdrop overlay for mobile */}
      <div
        className={`fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden ${
          open ? 'block' : 'hidden'
        }`}
        onClick={() => setOpen(false)}
      ></div>

      {/* Sidebar container */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-[12.8rem] overflow-y-auto transition duration-300 transform bg-white lg:translate-x-0 lg:static lg:inset-0 ${
          open ? 'translate-x-0 ease-out' : '-translate-x-full ease-in'
        }`}
      >
        {/* Logo section */}
        <div 
          className="flex items-center justify-start pl-4 mt-5 mb-4 cursor-pointer"
          onClick={handleLogoClick}
        >
          <img src={logo} alt="Logo" className="h-8 w-auto" />
        </div>

        {/* Navigation menu */}
        <nav className="px-2">
          {currentUser && renderMenuItems()}
        </nav>

        {/* Bottom section with profile and auth buttons */}
        <div className="mt-auto px-2 mb-4 absolute bottom-0 w-full">
          {currentUser ? (
            <>
              <button
                onClick={() => setIsProfileModalOpen(true)}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg mb-2"
              >
                <div className="flex items-center justify-center w-6 h-6 rounded-full bg-secondary text-white mr-3">
                  <span className="text-xs font-medium">{userInitials || '--'}</span>
                </div>
                <span>Profile</span>
              </button>
              <button
                onClick={() => {
                  logout();
                  if (window.innerWidth < 1024) {
                    setOpen(false);
                  }
                }}
                className="w-full flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg"
              >
                <LogOut className="mr-3 h-4 w-4" />
                <span>Sign Out</span>
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="w-full flex items-center px-4 py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">
                <LogIn className="mr-3 h-4 w-4" />
                <span>Sign In</span>
              </Link>
              <Link to="/signup" className="w-full flex items-center px-4 py-2 my-1 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">
                <UserPlus className="mr-3 h-4 w-4" />
                <span>Sign Up</span>
              </Link>
            </>
          )}
        </div>
      </div>

      {/* Profile Modal - rendered outside of sidebar container */}
      {currentUser && (
        <UserProfileModal
          isOpen={isProfileModalOpen}
          onClose={() => setIsProfileModalOpen(false)}
          userEmail={currentUser.email}
        />
      )}
    </>
  );
};

export default Sidebar;