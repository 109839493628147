import React, { useState, useRef, useEffect, useMemo } from 'react';
import { ChevronUp, ChevronDown } from 'lucide-react';
import { sortData, formatColumnName, formatDate, formatNumber } from './dataProcessing';

const TableView = ({ 
  data, 
  schema, 
  initialSortColumn, 
  initialSortOrder, 
  onTextSelection,
  visibleColumns = [],
  columnOrder = [],
  onSortChange
}) => {
  const [sortConfig, setSortConfig] = useState({ 
    key: initialSortColumn || null, 
    direction: initialSortOrder || 'asc' 
  });
  const [tableHeight, setTableHeight] = useState('auto');
  const [tooltipContent, setTooltipContent] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ left: 0, width: 0 });
  const headerRef = useRef(null);
  const tableRef = useRef(null);

  // Use columnOrder if provided, otherwise fall back to schema fields
  const displayColumns = useMemo(() => {
    if (columnOrder.length > 0) {
      return columnOrder.filter(col => visibleColumns.includes(col));
    }
    return schema?.fields?.map(field => field.name) || [];
  }, [columnOrder, visibleColumns, schema]);

  useEffect(() => {
    if (headerRef.current && data.length > 7) {
      const headerHeight = headerRef.current.offsetHeight;
      const rowHeight = 40;
      setTableHeight(`${headerHeight + (rowHeight * 7)}px`);
    } else {
      setTableHeight('auto');
    }
  }, [data]);

  useEffect(() => {
    setSortConfig({ key: initialSortColumn, direction: initialSortOrder });
  }, [initialSortColumn, initialSortOrder]);

  const sortedData = useMemo(() => {
    if (!Array.isArray(data) || data.length === 0) return [];
    return sortData(data, sortConfig.key, sortConfig.direction);
  }, [data, sortConfig]);

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
    if (onSortChange) {
      onSortChange(key, direction);
    }
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key === columnName) {
      return sortConfig.direction === 'asc' ? 
        <ChevronUp className="inline-block w-4 h-4 ml-1" /> :
        <ChevronDown className="inline-block w-4 h-4 ml-1" />;
    }
    return null;
  };

  const handleTextSelection = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();
    if (selectedText) {
      const range = selection.getRangeAt(0);
      const startNode = range.startContainer.parentElement;
      const endNode = range.endContainer.parentElement;
      
      const selectedColumns = new Set();
      let currentNode = startNode;
      while (currentNode && currentNode !== endNode.nextSibling) {
        if (currentNode.dataset && currentNode.dataset.column) {
          selectedColumns.add(currentNode.dataset.column);
        }
        currentNode = currentNode.nextSibling || (currentNode.parentNode && currentNode.parentNode.nextSibling);
      }
      
      const rect = range.getBoundingClientRect();
      const selectionData = {
        text: selectedText,
        columns: Array.from(selectedColumns),
        position: {
          x: rect.left + rect.width / 2,
          y: rect.top - 20
        }
      };
      
      onTextSelection(selectionData);
    }
  };
  
  const getSchemaType = (fieldName) => {
    const field = schema?.fields?.find(f => f.name === fieldName);
    return field ? field.type : null;
  };

  const renderCellValue = (cellData, columnName) => {
    if (!cellData || cellData.displayValue === null || cellData.displayValue === undefined) {
      return 'N/A';
    }
    return cellData.displayValue;
  };

  const calculateColumnStats = (columnName) => {
    const values = sortedData.map(row => parseFloat(row[columnName].sortValue)).filter(val => !isNaN(val));
    if (values.length === 0) return null;

    const sum = values.reduce((acc, val) => acc + val, 0);
    const avg = sum / values.length;
    const min = Math.min(...values);
    const max = Math.max(...values);

    return {
      sum: formatNumber(sum, columnName),
      avg: formatNumber(avg, columnName),
      min: formatNumber(min, columnName),
      max: formatNumber(max, columnName)
    };
  };

  const handleHeaderHover = (event, columnName) => {
    if (sortedData.length <= 1) {
      setTooltipContent(null);
      return;
    }

    const schemaType = getSchemaType(columnName);
    if (schemaType && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(schemaType)) {
      const stats = calculateColumnStats(columnName);
      if (stats) {
        setTooltipContent(
          <div className="p-2 text-sm">
            <div>Sum: {stats.sum}</div>
            <div>Avg: {stats.avg}</div>
            <div>Min: {stats.min}</div>
            <div>Max: {stats.max}</div>
          </div>
        );
        const headerCell = event.target;
        const headerRect = headerCell.getBoundingClientRect();
        const tableRect = tableRef.current.getBoundingClientRect();
        setTooltipPosition({ 
          left: headerRect.left - tableRect.left, 
          width: headerRect.width 
        });
      }
    }
  };

  const handleHeaderLeave = () => {
    setTooltipContent(null);
  };

  if (!Array.isArray(data) || data.length === 0) return <div>No data available</div>;

  return (
    <div className="relative" ref={tableRef}>
      <div className="overflow-x-auto" style={{ height: tableHeight, position: 'relative' }}>
        <table className="w-full border-collapse border border-gray-300">
          <thead ref={headerRef} className="sticky top-0 bg-gray-100" style={{ zIndex: 2 }}>
            <tr>
              {displayColumns.map(columnName => (
                <th 
                  key={columnName} 
                  className="border border-gray-300 p-2 text-sm font-semibold text-left cursor-pointer hover:bg-gray-200 transition-colors"
                  onClick={() => requestSort(columnName)}
                  onMouseEnter={(e) => handleHeaderHover(e, columnName)}
                  onMouseLeave={handleHeaderLeave}
                >
                  {formatColumnName(columnName)}
                  {getSortIcon(columnName)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="overflow-y-auto" onMouseUp={handleTextSelection}>
            {sortedData.map((row, index) => (
              <tr key={index} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                {displayColumns.map((columnName) => (
                  <td 
                    key={columnName} 
                    className="border border-gray-300 p-2 text-sm text-left"
                    data-column={columnName}
                  >
                    {renderCellValue(row[columnName], columnName)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {tooltipContent && sortedData.length > 1 && (
        <div
          className="absolute bg-white border border-gray-300 rounded shadow-md z-10"
          style={{
            left: `${tooltipPosition.left}px`,
            width: `${tooltipPosition.width}px`,
            top: `${headerRef.current.offsetHeight}px`,
          }}
        >
          {tooltipContent}
        </div>
      )}
    </div>
  );
};

export default TableView;