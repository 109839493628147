import React, { useState, useEffect, useRef } from 'react';
import { Clock } from 'lucide-react';

const RecentSearches = ({ onSelectQuery, isVisible, setIsVisible, recentSearches, highlightedIndex, onKeyDown }) => {
  const listRef = useRef(null);

  useEffect(() => {
    if (highlightedIndex !== -1 && listRef.current) {
      const highlightedElement = listRef.current.children[highlightedIndex];
      if (highlightedElement) {
        highlightedElement.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [highlightedIndex]);

  const getTimeAgo = (timestamp) => {
    if (!timestamp) return 'Unknown time';

    // Handle Firestore Timestamp format
    let searchTime;
    if (timestamp._seconds !== undefined) {
      // If it's a Firestore timestamp
      searchTime = new Date(timestamp._seconds * 1000);
    } else if (timestamp.seconds !== undefined) {
      // Alternative Firestore timestamp format
      searchTime = new Date(timestamp.seconds * 1000);
    } else {
      // Regular date string/timestamp
      searchTime = new Date(timestamp);
    }
    
    if (isNaN(searchTime.getTime())) {
      return 'Invalid date';
    }

    const now = new Date();
    const diffInMinutes = Math.floor((now - searchTime) / (1000 * 60));
    
    if (diffInMinutes < 1) return 'Just now';
    if (diffInMinutes < 60) return `${diffInMinutes}m ago`;
    if (diffInMinutes < 1440) return `${Math.floor(diffInMinutes / 60)}h ago`;
    return `${Math.floor(diffInMinutes / 1440)}d ago`;
  };

  if (!isVisible || recentSearches.length === 0) return null;

  return (
    <div className="absolute z-10 w-full mt-1 bg-gray-700 border border-secondary rounded-md shadow-lg focus-within:ring-2 focus-within:ring-secondary">
      <div className="px-4 py-2 text-sm text-gray-400 border-b border-gray-600">
        Recent Questions
      </div>
      <ul ref={listRef} className="py-1 max-h-60 overflow-y-auto">
        {recentSearches.map((search, index) => (
          <li
            key={search.id}
            className={`group px-4 py-2 hover:bg-gray-800 cursor-pointer text-white transition-all duration-200 ease-in-out rounded-md border border-transparent focus:ring-2 focus:ring-secondary ${
              index === highlightedIndex ? 'bg-gray-800' : ''
            }`}
            onClick={() => onSelectQuery(search)}
          >
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <p className="truncate">{search.userQuery}</p>
                <div className="flex items-center mt-1 text-xs text-gray-400">
                  <Clock size={12} className="mr-1" />
                  {getTimeAgo(search.timestamp)}
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RecentSearches;