// config.js

const config = {
  projectId: 'miloubar-nutrition',
  domain: 'https://research.miloubar.tangoai.co',
  
  sidebarPages: {
    admin: ['Ask', 'Dashboard', 'Reports', 'Playground', 'Operate', 'Plan'],
    user: ['Ask', 'Dashboard', 'Plan']
  },
  
  exemptedColumns: [
    'Invoice_Number', 
    'Customer_UID', 
    'invoice date', 
    'shipping date', 
    'year', 
    'YEAR', 
    'Year', 
    'Yealry%', 
    'Month', 
    'MONTH'
  ]
};

export default config;