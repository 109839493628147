import React, { useEffect, useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './pages/Home';
import DynamicQuery from './pages/DynamicQuery';
import StaticDashboard from './pages/StaticDashboard';
import Login from './pages/SignIn';
import Signup from './pages/Signup';
import PasswordReset from './pages/PasswordReset';
import Plan from './pages/Plan';
import Optimization from './pages/Optimization';
import Ask from './pages/Ask';
import UserPreferencesModal from './components/UserPreferencesModal';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { ThemeProvider } from './contexts/ThemeContext';
import { DashboardProvider } from './contexts/DashboardContext';
import { AskProvider } from './contexts/AskContext';
import './assets/bubblingLoader.css';

function AppContent() {
  const mainContentRef = useRef(null);
  const { currentUser, isAdmin, checkAdminStatus, isRegistrationComplete } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (currentUser) {
      console.log('AppContent: Current user detected, checking admin status');
      const refreshTokenAndCheckAdmin = async () => {
        const adminStatus = await checkAdminStatus(currentUser);
        console.log('AppContent: Admin status after check:', adminStatus);
      };

      refreshTokenAndCheckAdmin();

      // Refresh token and check admin status every 5 minutes
      const intervalId = setInterval(refreshTokenAndCheckAdmin, 5 * 60 * 1000);

      return () => clearInterval(intervalId);
    } else {
      console.log('AppContent: No current user');
    }
  }, [currentUser, checkAdminStatus]);

  const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password'];
  
  // Only hide layout for protected routes when registration is not complete
  const shouldShowLayout = isRegistrationComplete || 
    ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) ||
    !currentUser;

  return (
    <Layout mainContentRef={mainContentRef} showSidebar={shouldShowLayout}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/reset-password" element={<PasswordReset />} />
        <Route path="/" element={<PrivateRoute><Ask mainContentRef={mainContentRef} /></PrivateRoute>} />
        <Route path="/dashboard" element={<PrivateRoute><Home mainContentRef={mainContentRef} /></PrivateRoute>} />
        <Route path="/dynamic" element={<PrivateRoute><DynamicQuery mainContentRef={mainContentRef} /></PrivateRoute>} />
        <Route path="/static-dashboard" element={<PrivateRoute><StaticDashboard /></PrivateRoute>} />
        <Route path="/plan" element={<PrivateRoute><Plan /></PrivateRoute>} />
        <Route path="/optimization" element={<PrivateRoute><Optimization /></PrivateRoute>} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Layout>
  );
}

const PrivateRoute = ({ children }) => {
  const { 
    currentUser, 
    isAdmin, 
    isRegistrationComplete, 
    registrationChecked,
    saveUserPreferences 
  } = useAuth();
  const location = useLocation();
  const [preferencesLoading, setPreferencesLoading] = useState(false);

  const handlePreferencesSubmit = async (preferences) => {
    try {
      setPreferencesLoading(true);
      await saveUserPreferences(currentUser.email, preferences);
    } catch (error) {
      console.error('Error saving preferences:', error);
    } finally {
      setPreferencesLoading(false);
    }
  };

  // If not logged in, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Show loading state while checking registration
  if (!registrationChecked) {
    return (
      <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-50">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  // If registration is not complete and we're not on an allowed path,
  // force the user to complete registration
  if (!isRegistrationComplete && !['/login', '/signup', '/reset-password'].includes(location.pathname)) {
    return (
      <div className="fixed inset-0 bg-gray-100 flex items-center justify-center z-50">
        <UserPreferencesModal
          isOpen={true}
          onClose={() => null}
          onSubmit={handlePreferencesSubmit}
          isLoading={preferencesLoading}
        />
      </div>
    );
  }

  // If all checks pass, render the protected content
  return React.cloneElement(children, { isAdmin });
};

function App() {
  useEffect(() => {
    const currentBuildTime = localStorage.getItem('buildTime');
    const newBuildTime = process.env.REACT_APP_BUILD_TIME;

    if (currentBuildTime !== newBuildTime) {
      localStorage.setItem('buildTime', newBuildTime);
      if (currentBuildTime) {
        window.location.reload(true);
      }
    }
  }, []);

  return (
    <ThemeProvider>
      <AuthProvider>
        <DashboardProvider>
          <AskProvider>
            <Router>
              <AppContent />
            </Router>
          </AskProvider>
        </DashboardProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;