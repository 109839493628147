import React, { useState, useRef, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { GripVertical, Check, Square, ChevronDown, X } from 'lucide-react';

const ColumnManager = ({ 
  columns, 
  visibleColumns, 
  setVisibleColumns, 
  columnOrder, 
  setColumnOrder,
  schema
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownWidth, setDropdownWidth] = useState(0);
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const itemHeight = 64;
  const maxHeight = itemHeight * 5;

  const formatColumnName = (columnName) => {
    return columnName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  useEffect(() => {
    if (buttonRef.current) {
      const updateWidth = () => {
        const width = buttonRef.current.offsetWidth;
        setDropdownWidth(width);
      };

      updateWidth();
      
      const resizeObserver = new ResizeObserver(updateWidth);
      resizeObserver.observe(buttonRef.current);

      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(columnOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setColumnOrder(items);
  };

  const toggleColumnVisibility = (columnName) => {
    const isCurrentlyVisible = visibleColumns.includes(columnName);
    const newVisibleColumns = isCurrentlyVisible
      ? visibleColumns.filter(col => col !== columnName)
      : [...visibleColumns, columnName];
    
    if (newVisibleColumns.length > 0) {
      setVisibleColumns(newVisibleColumns);
    }
  };

  const toggleAllColumns = () => {
    const allVisible = visibleColumns.length === columnOrder.length;
    setVisibleColumns(allVisible ? [columnOrder[0]] : [...columnOrder]);
  };

  const getColumnType = (columnName) => {
    const field = schema?.fields?.find(f => f.name === columnName);
    return field?.type || 'Unknown';
  };

  const areAllColumnsVisible = visibleColumns.length === columnOrder.length;

  return (
    <div className="relative" ref={containerRef}>
      <button
        ref={buttonRef}
        onClick={() => setIsOpen(!isOpen)}
        className={`w-full border rounded-lg p-3 flex justify-between items-center transition-colors duration-200 ${
          isOpen 
            ? 'bg-gray-700 text-white border-gray-700 shadow-md' 
            : 'bg-white text-gray-700 border-gray-200 hover:text-secondary hover:border-secondary'
        }`}
      >
        <div className="flex items-center space-x-2">
          <span className={`text-sm font-medium ${isOpen ? 'text-white' : 'text-text'}`}>
            Manage Columns
          </span>
          <span className={`text-xs ${isOpen ? 'text-gray-200' : 'text-text-lighter'}`}>
            ({visibleColumns.length} of {columnOrder.length} visible)
          </span>
        </div>
        <ChevronDown
          size={16}
          className={`transform transition-all duration-200 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>

      {isOpen && (
        <div 
          className="absolute right-0 mt-2 bg-white border rounded-lg shadow-lg z-50 overflow-hidden"
          style={{ width: `${dropdownWidth}px` }}
        >
          <div className="bg-gray-100 px-6 py-4 flex justify-between items-center">
            <h2 className="text-xl font-semibold text-gray-800">Manage Columns</h2>
            <button
              onClick={() => setIsOpen(false)}
              className="text-gray-600 hover:text-gray-800 transition-colors duration-150"
            >
              <X size={24} />
            </button>
          </div>

          <div className="p-4">
            <div className="mb-3 pb-3 border-b border-gray-200">
              <button
                onClick={toggleAllColumns}
                className="flex items-center space-x-2 text-sm text-gray-700 hover:text-gray-900"
              >
                <div className={`p-1 rounded ${
                  areAllColumnsVisible
                    ? 'text-blue-600 bg-blue-100'
                    : 'text-gray-400 bg-gray-200'
                }`}>
                  {areAllColumnsVisible ? <Check size={14} /> : <Square size={14} />}
                </div>
                <span>
                  {areAllColumnsVisible ? 'Deselect All' : 'Select All'}
                </span>
              </button>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="columns">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="space-y-2 overflow-y-auto"
                    style={{
                      maxHeight: `${maxHeight}px`,
                      scrollbarWidth: 'thin',
                      scrollbarColor: '#CBD5E1 transparent'
                    }}
                  >
                    {columnOrder.map((columnName, index) => (
                      <Draggable 
                        key={columnName} 
                        draggableId={columnName} 
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            className={`flex items-center justify-between p-2 bg-gray-100 rounded ${
                              snapshot.isDragging ? 'shadow-lg' : ''
                            }`}
                          >
                            <div className="flex items-center space-x-3 flex-1">
                              <div
                                {...provided.dragHandleProps}
                                className="cursor-grab text-gray-500 hover:text-gray-700"
                              >
                                <GripVertical size={20} />
                              </div>
                              <button
                                onClick={() => toggleColumnVisibility(columnName)}
                                className={`p-1 rounded ${
                                  visibleColumns.includes(columnName)
                                    ? 'text-blue-600 bg-blue-100'
                                    : 'text-gray-400 bg-gray-200'
                                }`}
                              >
                                {visibleColumns.includes(columnName) ? (
                                  <Check size={14} />
                                ) : (
                                  <Square size={14} />
                                )}
                              </button>
                              <div className="flex-1">
                                <div className="text-sm font-medium text-gray-700">
                                  {formatColumnName(columnName)}
                                </div>
                                <div className="text-xs text-gray-500">
                                  {getColumnType(columnName)}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      )}
    </div>
  );
};

export default ColumnManager;